import axios from 'axios'

//创建提交
export function topicAdd(_this,data) {

    return axios({
      method: 'post',
      url: _this.apiUrl+'/h5topic/topic_add',
      data: data
    })
  }

//商品列表
export function goodsList(_this,data) {

    return axios({
      method: 'get',
      url: _this.apiUrl+'/h5topic/goods_list?keyword='+data,
      data: data
    })
  }
  
//商品列表
export function goodsList1(_this,data) {
  return axios({
	method: 'get',
	url: _this.apiUrl+'/h5topic/goods_list?page='+data.page+"&limit="+data.limit+"&keyword="+data.keyword,
  data
  })
}

//编辑信息
export function topicInfo(_this,data) {
    console.log(data)
    return axios({
      method: 'get',
      url: _this.apiUrl+'/h5topic/topic_edit_info?id='+data,
      data: data
    })
  }

//   上传图片
export function imgUpload(_this,data) {
    return axios({
      method: 'post',
      url: _this.apiUrl+'/upload',
      data: data
    })
  }

//编辑提交
export function topicEdit(_this,data) {
    return axios({
      method: 'post',
      url: _this.apiUrl+'/h5topic/topic_edit',
      data: data
    })
  }

//数据加密

export function topicadPassword(_this,data) {
  return axios({
    method: 'post',
    url: _this.apiUrl+'/h5topic/topic',
    data: data
  })
}
//商品列表

export function h5topicGoodsList(_this,data) {
  return axios({
    method: 'post',
    url: _this.apiUrl+'/h5topic/goods_list',
    data: data
  })
}