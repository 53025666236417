import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {post} from './utils/http'

Vue.prototype.$post = post;

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$imageUrl = 'https://cdn.meileme.com/';

const debug = false // true : 测试服务器  false : 正式服务器
if (!debug) {
  Vue.prototype.apiUrl = 'https://api.meileme.com/api' // 正式接口域名
} else {
  Vue.prototype.apiUrl = 'http://api.master.dev.meileme.com/api' // 测试接口域名
}

new Vue({
  render: h => h(App),
}).$mount('#app')
