<template>
  <div class="info-content">
    <el-form label-width="80px">
      <el-form-item label="页面标题">
        <el-input v-model="list.title"></el-input>
      </el-form-item>
      <el-form-item label="页面备注">
        <el-input type="textarea" :rows="4" v-model="list.remarks"></el-input>
      </el-form-item>
      <el-form-item label="背景颜色">
        <el-color-picker
          v-model="list.backgroundColor"
          show-alpha
        ></el-color-picker>
      </el-form-item>
      <el-form-item label="分享图标">
        <el-upload
		  ref="upload"
          class="avatar-uploader"
          action="https://api.meileme.com/api/upload"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
        >
          <img v-if="list.imageUrl" :src="$imageUrl+list.imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: ["data", "className"],
  data() {
    return {
      list: {},
      action: this.apiUrl + "/upload",
      imageUrl:''
    };
  },
  mounted() {
    this.list = this.data;
  },
  methods: {
    handleAvatarSuccess(res, file) {
        console.log(file)
      this.list.imageUrl = file.response.pathfile;
    },
    beforeAvatarUpload(file) {
	  if(!(file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
		  this.$message.error('请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片')
		}
		let size = file.size / 1024 / 1024 / 2
		if(size > 2) {
		  this.$message.error("上传头像图片大小不能超过 2MB!");
		}
    },
  },
};
</script>

<style lang="scss" scoped>
.info-content {
  padding: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #ececec;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
